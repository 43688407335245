
// import React, { useState } from 'react';
// import Register from "../Register/Register";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate, useLocation } from "react-router-dom";
// import loginService from '../../../services/login.service';
// import login from '../../../assets/images/banner/banner.png';

// function LoginForm() {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [student_email, setEmail] = useState('');
//   const [student_password, setPassword] = useState('');
//   const [emailError, setEmailError] = useState('');
//   const [passwordError, setPasswordError] = useState('');
//   const [serverError, setServerError] = useState('');

//   const [isRegister, setIsRegister] = useState(false);
//   const [showPassword, setShowPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(prevShowPassword => !prevShowPassword);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     let valid = true;

//     // Email validation
//     if (!student_email) {
//       setEmailError('Please enter your email address first');
//       valid = false;
//     } else if (!student_email.includes('@')) {
//       setEmailError('Invalid email format');
//     } else {
//       const regex = /^\S+@\S+\.\S+$/;
//       if (!regex.test(student_email)) {
//         setEmailError('Invalid email format');
//         valid = false;
//       } else {
//         setEmailError('');
//       }
//     }

//     // Password validation
//     if (!student_password || student_password.length < 6) {
//       setPasswordError('Password must be at least 6 characters long');
//       valid = false;
//     } else {
//       setPasswordError('');
//     }

//     if (!valid) {
//       return;
//     }

//     // Handle form submission
//     const formData = {
//       student_email,
//       student_password
//     };

//     const loginStudent = loginService.logIn(formData);

//     loginStudent.then((response) => response.json())
//       .then((response) => {
//         if (response.status === 'success') {
//           if (response.data.student_token) {
//             localStorage.setItem("student", JSON.stringify(response.data));
//           }

//           if (location.pathname === '/login') {
//             window.location.replace('/');
//           } else {
//             window.location.reload();
//           }
//         } else {
//           setServerError(response.message);
//         }
//       })
//       .catch((err) => {
//         setServerError('An error has occurred. Please try again later.' + err);
//       });
//   };

//   return (
//     <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${login})` }}>
//       <div className={`relative w-96 transition-transform duration-500 ${isRegister ? "transform " : "transform translate-x-00"}`}>
//         {!isRegister && (
//           <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg p-10 shadow-lg">
//             <h2 className="text-3xl font-bold text-center text-white mb-8">Login</h2>
//             <form className="space-y-6" onSubmit={handleSubmit}>
//               <div>
//                 <label htmlFor="username" className="block text-sm font-medium text-white">
//                   Email
//                 </label>
//                 {serverError && (
//                   <div className="validation-error" role="alert">
//                     {serverError}
//                   </div>
//                 )}
//                 <input
//                   type="email"
//                   name="employee_email"
//                   value={student_email}
//                   onChange={event => setEmail(event.target.value)}
//                   placeholder="Email"
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                 />
//                 {emailError && (
//                   <div className="validation-error" role="alert">
//                     {emailError}
//                   </div>
//                 )}
//               </div>
//               <div>
//                 <label htmlFor="password" className="block text-sm font-medium text-white">
//                   Password
//                 </label>
//                 <input
//                   type={showPassword ? "text" : "password"}
//                   name="student_password"
//                   value={student_password}
//                   onChange={event => setPassword(event.target.value)}
//                   placeholder="Password"
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//                 />
//                 <div
//                   className="absolute inset-y-0 right-0 pr-16 pt-6 flex items-center cursor-pointer"
//                   onClick={togglePasswordVisibility}
//                 >
//                   <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="text-gray-400" />
//                 </div>
//               </div>
//               <div className="flex items-center justify-between">
//                 <div className="flex items-center">
//                   <input
//                     id="remember-me"
//                     name="remember-me"
//                     type="checkbox"
//                     className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
//                   />
//                   <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
//                     Remember me
//                   </label>
//                 </div>
//                 <div className="text-sm">
//                   <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
//                     Forgot password?
//                   </a>
//                 </div>
//               </div>
//               <div>
//                 <button
//                   type="submit"
//                   className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 >
//                   Login
//                 </button>
//               </div>
//               <div className="text-center text-sm text-red-500">
//                 <p>
//                   Don't have an account?{" "}
//                   <a
//                     href="#"
//                     className="font-medium text-white hover:text-indigo-500"
//                     onClick={() => setIsRegister(true)}
//                   >
//                     Register
//                   </a>
//                 </p>
//               </div>
//             </form>
//           </div>
//         )}
//         {isRegister && <Register />}
//       </div>
//     </div>
//   );
// }

// export default LoginForm;
import React, { useState } from "react";
import Register from "../Register/Register";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import loginService from "../../../services/login.service";
import login from "../../../assets/images/banner/banner.png";

function LoginForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [student_email, setEmail] = useState("");
  const [student_password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [serverError, setServerError] = useState("");

  const [isRegister, setIsRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    let valid = true;

    // Email validation
    if (!student_email) {
      setEmailError("Please enter your email address first");
      valid = false;
    } else if (!student_email.includes("@")) {
      setEmailError("Invalid email format");
    } else {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(student_email)) {
        setEmailError("Invalid email format");
        valid = false;
      } else {
        setEmailError("");
      }
    }

    // Password validation
    if (!student_password || student_password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) {
      return;
    }

    setLoading(true); // Set loading to true when submitting

    // Handle form submission
    const formData = {
      student_email,
      student_password
    };

    const loginStudent = loginService.logIn(formData);

    loginStudent
      .then(response => response.json())
      .then(response => {
        setLoading(false); // Set loading to false when the request is complete
        if (response.status === "success") {
          if (response.data.student_token) {
            localStorage.setItem("student", JSON.stringify(response.data));
          }

          if (location.pathname === "/login") {
            window.location.replace("/");
          } else {
            window.location.reload();
          }
        } else {
          setServerError(response.message);
        }
      })
      .catch(err => {
        setLoading(false); // Set loading to false on error
        setServerError("An error has occurred. Please try again later." + err);
      });
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${login})` }}
    >
      <div
        className={`relative w-96 transition-transform duration-500 ${isRegister
          ? "transform "
          : "transform translate-x-00"}`}
      >
        {!isRegister &&
          <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg p-10 shadow-lg">
            <h2 className="text-3xl font-bold text-center text-white mb-8">
              Login
            </h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-white"
                >
                  Email
                </label>
                {serverError &&
                  <div className="validation-error" role="alert">
                    {serverError}
                  </div>}
                <input
                  type="email"
                  name="employee_email"
                  value={student_email}
                  onChange={event => setEmail(event.target.value)}
                  placeholder="Email"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                {emailError &&
                  <div className="validation-error" role="alert">
                    {emailError}
                  </div>}
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-white"
                >
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="student_password"
                  value={student_password}
                  onChange={event => setPassword(event.target.value)}
                  placeholder="Password"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <div
                  className="absolute inset-y-0 right-0 pr-16 pt-6 flex items-center cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="text-gray-400"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-white"
                  >
                    Remember me
                  </label>
                </div>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${loading
                    ? "bg-gray-500"
                    : "bg-indigo-600 hover:bg-indigo-700"} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? "Loading..." : "Login"}
                </button>
              </div>
              <div className="text-center text-sm text-red-500">
                <p>
                  Don't have an account?{" "}
                  <a
                    href="#"
                    className="font-medium text-white hover:text-indigo-500"
                    onClick={() => setIsRegister(true)}
                  >
                    Register
                  </a>
                </p>
              </div>
            </form>
          </div>}
        {isRegister && <Register />}
      </div>
    </div>
  );
}

export default LoginForm;
