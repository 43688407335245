import React from 'react';

function Unauthorized() {
  return (
    <div>
      <h1>You don't have the authorization to access the page you requested</h1>
    </div>
  );
}

export default Unauthorized; 