
const api_url = "https://ba.ethioedutools.com";
// const api_url = process.env.REACT_APP_API_URL;
// const createQuestion = async dataOfQustio => {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json"
//     },
//     body: JSON.stringify(dataOfQustio)
//   };

//   console.log(requestOptions);
//   const response = await fetch(`${api_url}/api/questions`, requestOptions);

//   if (!response.ok) {
//     const errorData = await response.json();
//     throw new Error(errorData.error || "Failed to create question");
//   }
//   return response.json();
// };

const createQuestion = async dataOfQustio => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(dataOfQustio)
  };
  const response = await fetch(`${api_url}/api/questions`, requestOptions);
  if (!response.status) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to create question");
  }
  return response.json();
}; 
const updateProfileImage = async (userId, formData) => {
  const requestOptions = {
    method: "PUT",
    body: formData
  };

  try {
    const response = await fetch(
      `${api_url}/api/users/${userId}`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating profile image:", error);
    throw error;
  }
};
const postResult = async result => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(result)
  };

  console.log(requestOptions);
  const response = await fetch(`${api_url}/api/postResult`, requestOptions);

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to create question");
  }
  return response.json();
}; 
//postQustion(dataOfQustio)
const postQustion = async dataOfQustio => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(dataOfQustio)
  };
  const response = await fetch(`${api_url}/api/postQustion`, requestOptions);
  if (!response.status) {
    const errorData = await response.json();
    throw new Error(errorData.error || "Failed to create question");
  }
  return response.json();
}; 
const getUserScore = async (student_id) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
  };
  const response = await fetch(`${api_url}/api/getUserScore/${student_id}`, requestOptions);
  return response;
};
const getAllQuestions = async (grade) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
 
  };
  const response = await fetch(`${api_url}/api/getquestion/${grade}`, requestOptions);
  console.log(response)
  return response;
};
const getAllOrdinerryQuestions = async (selectSet) => {
  const { subject, year } = selectSet;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  };
  const response = await fetch(`${api_url}/api/getAllOrdinerryQuestions?subject=${subject}&year=${year}`, requestOptions);
  return response;
};

const questionService = {
  createQuestion,
  updateProfileImage,
  getAllQuestions,
  postResult,
  getUserScore,
  postQustion,
  getAllOrdinerryQuestions
};

export default questionService;