import React from "react";

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-600 to-blue-500">
      <div className="text-center p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-5xl font-bold text-gray-800">Coming Soon</h1>
        <p className="mt-4 text-lg text-gray-600">
          We're working hard to bring you something amazing. Stay tuned!
        </p>
        <div className="mt-8">
          <input
            type="email"
            placeholder="Enter your email"
            className="px-4 py-2 w-64 rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-400 focus:outline-none"
          />
          <button className="ml-2 px-4 py-2 bg-blue-500 text-white font-semibold rounded-md shadow hover:bg-blue-600 transition duration-300">
            Notify Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
