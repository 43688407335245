import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import questionService from "../../../services/question.service";
import { faUpload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const subjects = [
  {
    name: 'Mathematics for Natural',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://images.unsplash.com/photo-1509228468518-180dd4864904?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bWF0aHN8ZW58MHx8MHx8fDA%3D', // Replace with actual image path
  },
  {
    name: 'Biology',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://plus.unsplash.com/premium_photo-1681426647826-34e345bc8a18?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjF8fGJpb2xvZ3l8ZW58MHx8MHx8fDA%3D', // Replace with actual image path
  },
  {
    name: 'English',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://media.istockphoto.com/id/683574842/photo/exam.jpg?s=1024x1024&w=is&k=20&c=iP4z43NiVmVQtXauBdtaLJPHB_gKjCbCJp47uHvZl_o=', // Replace with actual image path
  },
  {
    name: 'Chemistry',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://plus.unsplash.com/premium_photo-1673892648220-9c9aa16d6294?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Y2hlbWlzdHJ5fGVufDB8fDB8fHww', // Replace with actual image path
  },
  {
    name: 'Aptitude',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://media.istockphoto.com/id/1147401545/photo/magnifying-glass-is-looking-at-leader-explains-employee-tactics-of-advertising-targeting.jpg?s=1024x1024&w=is&k=20&c=uGTWh2lubL9SlTgIzIHP4cEXSAGQVfMO0_bNOojdobU=', // Replace with actual image path
  },
  {
    name: 'Physics',
    description: 'Learn about algebra, geometry, and calculus.',
    image: 'https://images.unsplash.com/photo-1708011271954-c0d2b3155ded?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cGh5c2ljcyUyMGVxdWF0aW9ufGVufDB8fDB8fHww', // Replace with actual image path
  },
];

const SubjectCard = ({ subject, onSelect }) => {
  return (
    <motion.div
      className="w-48 rounded overflow-hidden shadow-lg bg-white cursor-pointer m-2"
      whileHover={{ scale: 1.05 }}
      onClick={() => onSelect(subject)}
    >
      <img className="w-full h-32 object-cover" src={subject.image} alt={`${subject.name} image`} />
      <div className="px-4 py-2">
        <div className="font-bold text-md mb-1">{subject.name}</div>
        <p className="text-gray-700 text-sm">{subject.description}</p>
      </div>
      <div className="px-4 pt-2 pb-2 text-center">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm">
          Select
        </button>
      </div>
    </motion.div>
  );
};

const FileUpload = () => {
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;
        const workbook = XLSX.read(binaryStr, { type: 'binary' });

        workbook.SheetNames.forEach((sheet) => {
          const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
          setData(data);
          setIsVisible(false); // Hide the upload card
        });
      };

      reader.readAsBinaryString(file);
    });
  }, []);

  const handleInputChange = (event, rowIndex, key) => {
    const newData = [...data];
    newData[rowIndex][key] = event.target.value;
    setData(newData);
  };

  const handleSend = async () => {
    const dataOfQustio =  data ;
    try {
      const response = await questionService.createQuestion(dataOfQustio);
      console.log(response);
      if (response.status) {
        console.log('Result submitted successfully');
      } else {
        console.error('Failed to submit result');
      }
    } catch (error) {
      console.error('Error submitting result:', error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {!selectedSubject ? (
        <div className="flex flex-wrap justify-center gap-4 p-4">
          {subjects.map((subject) => (
            <SubjectCard key={subject.name} subject={subject} onSelect={setSelectedSubject} />
          ))}
        </div>
      ) : (
        <>
          {isVisible && (
            <motion.div
              {...getRootProps()}
              className="w-64 p-6 bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <input {...getInputProps()} />
              <div className="text-center">
                <FontAwesomeIcon icon={faUpload} className="text-4xl text-blue-500 mb-2" />
                <p className="text-gray-600">Drag 'n' drop some files here, or click to select files</p>
              </div>
            </motion.div>
          )}

          {data.length > 0 && (
            <>
              <div className="mt-8 w-full overflow-auto">
                <table className="min-w-full bg-white border">
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map((key) => (
                        <th
                          key={key}
                          className="py-2 px-4 bg-gray-200 border-b border-gray-300 text-gray-700 text-left"
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {Object.keys(row).map((key, colIndex) => (
                          <td
                            key={colIndex}
                            className="py-2 px-4 border-b border-gray-300 text-gray-700"
                          >
                            <input
                              type="text"
                              value={row[key]}
                              onChange={(event) => handleInputChange(event, rowIndex, key)}
                              className="w-full bg-gray-100 border border-gray-300 rounded-md p-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <motion.button
                onClick={handleSend}
                className="mt-6 px-4 py-2 bg-green-500 text-white rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 flex items-center"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                Send
              </motion.button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FileUpload;
