import React from "react";
import { FaTelegramPlane, FaYoutube } from "react-icons/fa";

function Footer(props) {
  return <footer id="contact" className="bg-gray-900 text-white">
      <div className="py-10">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="footer-info-box">
              <div className="info-inner">
                <div className="flex items-center mb-4">
                  <span className="text-xl text-yellow-500" />
                  <div className="ml-3">
                    <div>
                      Ethiopia,<br />Addis Ababa
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-info-box">
              <div className="info-inner">
                <div className="flex items-center mb-4">
                  <span className="text-xl text-yellow-500">✉️</span>
                  <div className="ml-3">
                    <div>
                      Email us: <br />
                      <a href="mailto:contact@autorex.com" className="text-yellow-500">
                        ethioedusupport@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-info-box">
              <div className="info-inner">
                <div className="flex items-center mb-4">
                  <span className="text-xl text-yellow-500">📞</span>
                  <div className="ml-3">
                    <div>
                      Call us on: <br />
                      <strong>0701415307</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 py-8">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="footer-column">
              <div className="widget">
                <p>
                  Empowering students with a revolutionary EduEthiopia,
                  ensuring academic success and community growth. Bridging the
                  gap between education and society, we foster a brighter
                  future for all. Together, we transform learning experiences
                  into lifelong achievements.
                </p>
              </div>
            </div>
            <div className="footer-column grid grid-cols-2 gap-4">
              <div className="widget">
                <h4 className="text-xl mb-4">Useful Links</h4>
                <ul className="list-none">
                  <li className="mb-2">
                    <a href="#" className="text-yellow-500 hover:text-yellow-300">
                      Home
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#" className="text-yellow-500 hover:text-yellow-300">
                      About Us
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#" className="text-yellow-500 hover:text-yellow-300">
                      Appointment
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="#" className="text-yellow-500 hover:text-yellow-300">
                      Testimonials
                    </a>
                  </li>
                  <li className="mb-2">
                    <a href="contact" className="text-yellow-500 hover:text-yellow-300">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-column">
              <div className="widget">
                <h4 className="text-xl mb-4">Newsletter</h4>
                <p>Get the latest updates and offers.</p>
                <div className="newsletter-form mt-4">
                  {/* Placeholder for newsletter form */}
                </div>
                <ul className="flex space-x-4 mt-4">
                  <li>
                    <a href="#" className="text-xl text-yellow-500 hover:text-yellow-300">
                      <span className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-xl text-yellow-500 hover:text-yellow-300">
                      <span className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-xl text-yellow-500 hover:text-yellow-300">
                      <span className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/YOUR_TELEGRAM_CHANNEL" target="_blank" rel="noopener noreferrer" className="text-xl text-yellow-500 hover:text-yellow-300" aria-label="Telegram">
                      <FaTelegramPlane className="text-3xl" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/YOUR_CHANNEL_ID" target="_blank" rel="noopener noreferrer" className="text-red-600 hover:text-red-800 transition-colors duration-300" aria-label="YouTube">
                      <FaYoutube className="text-3xl" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 py-4">
        <div className="container mx-auto text-center">
          <div className="text-gray-500">
            © Copyright <a href="#" className="text-yellow-500">
              EduEthiopia
            </a> 2024. All rights reserved.
          </div>
        </div>
      </div>
    </footer>;
}

export default Footer;
