// // src/components/Year.js
// import React, { useState, useCallback } from "react";
// import { motion } from 'framer-motion';
// import Lottie from "lottie-react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import questionService from "../../../services/question.service";
// import loadingAnimation from "../Lottia/loading.json";
// import { faCalculator, faFlask, faGamepad, faHistory, faBook, faAtom } from '@fortawesome/free-solid-svg-icons';
// import QuestionCard from './QuestionCard '; // Import the QuestionCard component

// const icons = {
//   faCalculator, // Mathematics, Chemistry
//   faFlask,      // Science, Physics, Chemistry
//   faHistory,    // History, Aptitude
//   faBook,       // Default icon
//   faAtom,       // Biology
//   faGamepad,
// };

// const subjects = [
//   { name: 'Mathematics', description: 'Learn about numbers, equations, functions, and more.', icon: 'faCalculator' },
//   { name: 'English', description: 'Explore the wonders of language and literature.', icon: 'faBook' },
//   { name: 'Biology', description: 'Study living organisms and their vital processes.', icon: 'faAtom' },
//   { name: 'Chemistry', description: 'Learn about substances, their properties, and reactions.', icon: 'faFlask' },
//   { name: 'Physics', description: 'Understand the fundamental principles of matter and energy.', icon: 'faGamepad' },
//   { name: 'Aptitude', description: 'Assess and enhance your logical and problem-solving skills.', icon: 'faHistory' },
// ];

// const Year = () => {
//   const years = [2010, 2011, 2012, 2013, 2014, 2015, 2016];
//   const [selectedYear, setSelectedYear] = useState(2010);
//   const [questions, setQuestions] = useState([]);
//   const [error, setError] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [selectedSubject, setSelectedSubject] = useState(null);
//   const [minimized, setMinimized] = useState(false);

//   const handleChange = (event) => {
//     setSelectedYear(event.target.value);
//   };

//   const handleNext = useCallback(async () => {
//     if (!selectedYear || !selectedSubject) return;
//     setIsLoading(true);
//     try {
//       const selectSet = { subject: selectedSubject.name, year: selectedYear };
//       const response = await questionService.getAllOrdinerryQuestions(selectSet);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const data = await response.json();
//       console.log(data)
//       if (data.status === 'success' && Array.isArray(data.data)) {
//         const formattedQuestions = data.data.map(q => ({
//           question: q.Qustion, // Note: Consider fixing 'Qustion' typo in backend
//           ans:q.Ans,
//           choices: [q.ChoseA, q.ChoseB, q.ChoseC, q.ChoseD].filter(Boolean),
//           explanation: q.Explanation || 'No explanation provided'
//         }));
//         setQuestions(formattedQuestions);
//       } else {
//         setError("No questions available.");
//       }
//     } catch (err) {
//       console.error("Error fetching questions:", err);
//       setError("Failed to load questions. Please try again later.");
//     } finally {
//       setIsLoading(false);
//     }
//   }, [selectedYear, selectedSubject]);
//   const handleSelectSubject = (subject) => {
//     setSelectedSubject(subject);
//     setMinimized(true);
//   };
//   const handleExplain = (explanation) => {
//     alert(explanation); // Modify this to handle explanations as needed
//   };

//   if (isLoading) {
//     return (
//       <div className="flex justify-center items-center h-screen">
//         <Lottie animationData={loadingAnimation} style={{ width: 150, height: 150 }} />
//       </div>
//     );
//   }

//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 1 }}
//       className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-800"
//     >
//       {selectedSubject ? (
//         <>
//           {questions.length === 0 ? (
//             <>
//               <motion.label
//                 htmlFor="year"
//                 className="mb-2 text-lg font-medium"
//                 initial={{ y: -20 }}
//                 animate={{ y: 0 }}
//                 transition={{ duration: 0.5 }}
//               >
//                 Select Year for {selectedSubject.name}
//               </motion.label>
//               <motion.select
//                 id="year"
//                 value={selectedYear}
//                 onChange={handleChange}
//                 className="w-48 p-2 mb-4 bg-white text-gray-800 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//                 initial={{ scale: 0.8 }}
//                 animate={{ scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 whileHover={{ scale: 1.05 }}
//                 whileFocus={{ scale: 1.05 }}
//               >
//                 {years.map((year) => (
//                   <option key={year} value={year}>
//                     {year}
//                   </option>
//                 ))}
//               </motion.select>
//               <motion.div
//                 className="mt-4 text-lg font-semibold"
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1 }}
//               >
//                 Selected Year: <strong>{selectedYear}</strong>
//               </motion.div>
//               <motion.button
//                 onClick={handleNext}
//                 className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//                 initial={{ scale: 0.8 }}
//                 animate={{ scale: 1 }}
//                 transition={{ duration: 0.5 }}
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.9 }}
//               >
//                 Next
//               </motion.button>
//             </>
//           ) : (
//             <div className="mt-8 space-y-4 w-10/12 h-auto">
//               {questions.map((q, index) => (
//                 <QuestionCard
//                   key={index}
//                   answer={q.ans}
//                   question={q.question}
//                   choices={q.choices}
//                   explanation={q.explanation}
//                   onExplain={() => handleExplain(q.explanation)}
//                 />
//               ))}
//             </div>
//           )}
//           {error && <div className="mt-4 text-red-500">{error}</div>}
//         </>
//       ) : (
//         <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
//           {subjects.map((subject, index) => {
//             const icon = icons[subject.icon] || icons.faBook;
//             return (
//               <motion.div
//                 key={index}
//                 className={`p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-lg hover:border-solid border-1 border-sky-200 relative ${minimized && selectedSubject?.name === subject.name ? 'minimized-card' : ''}`}
//                 initial={{ opacity: 0, y: 50 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ delay: 0.2 * index, duration: 0.5 }}
//                 whileHover={{ zIndex: 10 }}
//               >
//                 <motion.div
//                   className="flex items-center justify-center h-24"
//                   initial={{ scale: 0.8, rotate: -20 }}
//                   animate={{ scale: 1, rotate: 0 }}
//                   transition={{ duration: 0.5 }}
//                 >
//                   <FontAwesomeIcon icon={icon} className="text-4xl text-blue-500" />
//                 </motion.div>
//                 {!minimized && (
//                   <div className="p-2">
//                     <h2 className="text-xl font-semibold text-gray-800">{subject.name}</h2>
//                     <p className="mt-1 text-gray-600">{subject.description}</p>
//                   </div>
//                 )}
//                 {!minimized && (
//                   <div className="flex justify-between mt-2">
//                     <button
//                       className="px-3 py-1 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
//                       onClick={() => handleSelectSubject(subject)}
//                     >
//                       Select
//                     </button>
//                   </div>
//                 )}
//               </motion.div>
//             );
//           })}
//         </div>
//       )}
//     </motion.div>
//   );
// };

// export default Year;
// src/components/Year.js
// src/components/Year.js
import React, { useState, useCallback, useEffect } from "react";
import { motion } from 'framer-motion';
import Lottie from "lottie-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import questionService from "../../../services/question.service";
import loadingAnimation from "../Lottia/loading.json";
import { faCalculator, faFlask, faGamepad, faHistory, faBook, faAtom } from '@fortawesome/free-solid-svg-icons';
import QuestionCard from './QuestionCard '; // Import the QuestionCard component

const icons = {
  faCalculator, // Mathematics, Chemistry
  faFlask,      // Science, Physics, Chemistry
  faHistory,    // History, Aptitude
  faBook,       // Default icon
  faAtom,       // Biology
  faGamepad,
};

const subjects = [
  { name: 'Mathematics', description: 'This is a collection of entrance exams from the past five years.', icon: 'faCalculator' },
  { name: 'English', description: 'This is a collection of entrance exams from the past five years.', icon: 'faBook' },
  { name: 'Biology', description: 'This is a collection of entrance exams from the past five years.', icon: 'faAtom' },
  { name: 'Chemistry', description: 'This is a collection of entrance exams from the past five years.', icon: 'faFlask' },
  { name: 'Physics', description: 'This is a collection of entrance exams from the past five years.', icon: 'faGamepad' },
  { name: 'Aptitude', description: 'This is a collection of entrance exams from the past five years.', icon: 'faHistory' },
];

const Year = () => {
  const years = [2010, 2011, 2012, 2013, 2014, 2015, 2016];
  const [selectedYear, setSelectedYear] = useState(2010);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [minimized, setMinimized] = useState(false);
  const [viewedSubjects, setViewedSubjects] = useState(() => {
    const saved = localStorage.getItem('viewedSubjects');
    return saved ? JSON.parse(saved) : {};
  });

  useEffect(() => {
    localStorage.setItem('viewedSubjects', JSON.stringify(viewedSubjects));
  }, [viewedSubjects]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleNext = useCallback(async () => {
    if (!selectedYear || !selectedSubject) return;
    setIsLoading(true);
    try {
      const selectSet = { subject: selectedSubject.name, year: selectedYear };
      const response = await questionService.getAllOrdinerryQuestions(selectSet);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      if (data.status === 'success' && Array.isArray(data.data)) {
        const formattedQuestions = data.data.map(q => ({
          question: q.Qustion, // Note: Consider fixing 'Qustion' typo in backend
          ans: q.Ans,
          choices: [q.ChoseA, q.ChoseB, q.ChoseC, q.ChoseD].filter(Boolean),
          explanation: q.Explanation || 'No explanation provided',
        }));
        setQuestions(formattedQuestions);
      } else {
        setError("No questions available.");
      }
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError("Failed to load questions. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [selectedYear, selectedSubject]);

  const handleSelectSubject = (subject) => {
    setSelectedSubject(subject);
    setMinimized(true);
    setViewedSubjects((prev) => ({ ...prev, [subject.name]: true }));
  };

  const handleExplain = (explanation) => {
    alert(explanation); // Modify this to handle explanations as needed
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Lottie animationData={loadingAnimation} style={{ width: 150, height: 150 }} />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-800"
    >
      {selectedSubject ? (
        <>
          {questions.length === 0 ? (
            <>
              <motion.label
                htmlFor="year"
                className="mb-2 text-lg font-medium"
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
              >
                Select Year for {selectedSubject.name}
              </motion.label>
              <motion.select
                id="year"
                value={selectedYear}
                onChange={handleChange}
                className="w-48 p-2 mb-4 bg-white text-gray-800 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
                whileFocus={{ scale: 1.05 }}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </motion.select>
              <motion.div
                className="mt-4 text-lg font-semibold"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                Selected Year: <strong>{selectedYear}</strong>
              </motion.div>
              <motion.button
                onClick={handleNext}
                className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Next
              </motion.button>
            </>
          ) : (
            <div className="mt-8 space-y-4 w-10/12 h-auto">
              {questions.map((q, index) => (
                <QuestionCard
                  key={index}
                  answer={q.ans}
                  question={q.question}
                  choices={q.choices}
                  explanation={q.explanation}
                  onExplain={() => handleExplain(q.explanation)}
                />
              ))}
            </div>
          )}
          {error && <div className="mt-4 text-red-500">{error}</div>}
        </>
      ) : (
        <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {subjects.map((subject, index) => {
            const icon = icons[subject.icon] || icons.faBook;
            const isSelected = minimized && selectedSubject?.name === subject.name;
            const isViewed = viewedSubjects[subject.name];
            return (
              <motion.div
                key={index}
                className={`p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-lg hover:border-solid border-1 border-sky-200 relative ${isSelected ? 'minimized-card' : ''}`}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 * index, duration: 0.5 }}
                whileHover={{ zIndex: 10 }}
              >
                <motion.div
                  className="flex items-center justify-center h-24"
                  initial={{ scale: 0.8, rotate: -20 }}
                  animate={{ scale: 1, rotate: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <FontAwesomeIcon icon={icon} className="text-4xl text-blue-500" />
                </motion.div>
                {!minimized && (
                  <div className="p-2">
                    <h2 className="text-xl font-semibold text-gray-800">{subject.name}</h2>
                    <p className="mt-1 text-gray-600">{subject.description}</p>
                  </div>
                )}
                {!minimized && (
                  <div className="flex justify-between mt-2">
                    <button
                      className="px-3 py-1 bg-blue-500 text-white rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => handleSelectSubject(subject)}
                    >
                      Select
                    </button>
                    {!isViewed && (
                      <span className="absolute top-2 right-2 text-xs bg-red-500 text-white px-2 py-1 rounded-full transform rotate-45">New</span>
                    )}
                  </div>
                )}
              </motion.div>
            );
          })}
        </div>
      )}
    </motion.div>
  );
};

export default Year;

