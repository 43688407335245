import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../../../../Contexts/AuthContext";
import loginService from "../../../../services/login.service";

function AdminMenu(props) {
 const { isLogged, setIsLogged, employee } = useAuth();
   const logOut = () => {
     // Call the logout function from the login service
     loginService.logOut();

     // Set the isLogged state to false
     setIsLogged(false);
     window.location.replace("/login");
   };
  return <div>
      <div class="admin-menu mb-4">
        <h2 class="text-xl font-bold">Manager Menu</h2>
      </div>
      <div class="list-group flex flex-col space-y-2">
        <Link to="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0d" class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          Dashboard
        </Link>
        <Link to={"/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0q"} class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          Add Questions
        </Link>
        <Link to={"/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN03"} class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          Add Questions online
        </Link>
        <Link to={"/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN034"} class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          uploads images
        </Link>
        <Link to="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0s" class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          Add Students
        </Link>
        <Link to="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0sl" class="list-group-item block py-2 px-4 bg-gray-100 rounded hover:bg-gray-200">
          Students List
        </Link>
        <Link to="/" className="theme-btn btn-style-one blue" onClick={logOut}>
          Log out
        </Link>
      </div>
    </div>;
}

export default AdminMenu;