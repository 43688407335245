// Import React and the Hooks we need here 
import React, { useState, useEffect, useContext } from "react";
// Import the Util function we created to handle the reading from the local storage 
import getAuth from '../util/auth';
// Create a context object  
const AuthContext = React.createContext();
// Create a custom hook to use the context
export const useAuth = () => {
  return useContext(AuthContext);
}
// Create a provider component  
export const AuthProvider = ({ children }) => {
  const [isLogged, setIsLogged] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStudent, setStudents] = useState(false);
  const [student, setStudent] = useState(null);
  

  const value = { isLogged, isAdmin, setIsAdmin, setIsLogged, isStudent, setStudents, student };
      console.log(isLogged)
      // console.log(student.student_id)
  useEffect(() => {
    // Retrieve the logged in user from local storage
    const loggedInStudent = getAuth();
    // console.log(loggedInstudent);
    loggedInStudent.then((response) => {
      console.log(response.student_role);
      if (response.student_token) {
        setIsLogged(true);
        // 1 is the student_role for admin
        if (response.student_role === 2) {
          setIsAdmin(true);
        }
        if (response.student_role === 1) {
          setStudents(true);
        }
        setStudent(response);
      }
    });
  }, []);
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

