// Function to read the data from the user's local storage  
const getAuth = async () => {
  const student = await JSON.parse(localStorage.getItem('student'));
  if (student && student.student_token) {
    const decodedToken = await decodeTokenPayload(student.student_token);
    student.student_role = decodedToken.student_role;
    student.student_id = decodedToken.student_id;
    student.student_first_name = decodedToken.student_first_name;
    return student;
  } else {
    return {};
  }
};

// Function to decode the payload from the token
// The purpose of this code is to take a JWT token, extract its payload, decode it from Base64Url encoding, and then convert the decoded payload into a JavaScript object for further use and manipulation
const decodeTokenPayload = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
};

export default getAuth;