import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const QuestionCard = ({ question, choices, explanation, answer }) => {
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [showExplanation, setShowExplanation] = useState(false);

  const choiceLabels = ["A", "B", "C", "D"];

  const handleChoiceChange = (event) => {
    const value = event.target.value;
    setSelectedChoice(value);

    // Get the index of the selected choice
    const choiceIndex = choices.indexOf(value);
    // Map the index to the corresponding label
    const selectedLabel = choiceLabels[choiceIndex];

    console.log("Selected Choice:", selectedLabel);
    console.log("Correct Answer:", answer);
  };

  const handleExplanationToggle = () => {
    setShowExplanation(!showExplanation);
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      style={{ width: '81.8182%', height: 'auto' }}
      className="p-4 border border-gray-300 rounded-md shadow-lg bg-white mx-auto"
    >
      <h3 className="text-lg font-semibold mb-2">{question}</h3>
      <div className="mb-2">
        {choices.length > 0 ? (
          choices.map((choice, index) => (
            <motion.div
              key={index}
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`flex items-center mb-1 ${
                selectedChoice === choice && choiceLabels[index] === answer ? 'bg-green-200' : ''
              } ${
                selectedChoice === choice && choiceLabels[index] !== answer ? 'bg-red-200' : ''
              }`}
            >
              <input
                type="radio"
                id={`choice${index}`}
                name="choices"
                value={choice}
                checked={selectedChoice === choice}
                onChange={handleChoiceChange}
                className="mr-2"
              />
              <label htmlFor={`choice${index}`}>{choiceLabels[index]}: {choice}</label>
            </motion.div>
          ))
        ) : (
          <div>No choices available.</div>
        )}
      </div>
      <button
        onClick={handleExplanationToggle}
        className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition-colors duration-300"
      >
        {showExplanation ? "Hide Explanation" : "Show Explanation"}
      </button>
      <AnimatePresence>
        {showExplanation && (
          <motion.div
            key="explanation"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-2 p-2 border-t border-gray-300 text-gray-700"
            style={{ maxHeight: '200px' }}
          >
            {explanation} 
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default QuestionCard;
