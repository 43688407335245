import React from "react";

const Customers = () => {
  return (
    <div>
      <h1>Customers Page</h1>
    </div>
  );
}

export default Customers; 