import React from 'react';
import { motion } from 'framer-motion';

const NoQuestionCard = () => {
  return (
    <motion.div
      className="flex items-center justify-center min-h-screen"
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
    >
      <motion.div
        className="bg-white p-8 rounded-lg shadow-lg max-w-sm text-center"
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.7, ease: 'easeOut' }}
      >
        <motion.h2
          className="text-xl font-semibold text-red-600"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          The question is not inserted
        </motion.h2>
      </motion.div>
    </motion.div>
  );
};

export default NoQuestionCard;