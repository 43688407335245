// Import from the env
// const api_url = process.env.REACT_APP_API_URL;
const api_url = "https://ba.ethioedutools.com";
// A function to send post request to create a new employee
const createStudent = async (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(formData)
  };
  console.log(requestOptions);
  const response = await fetch(`${api_url}/api/employee`, requestOptions);
  return response;
};

// A function to send get request to get all employees
const getAllStudents = async token => {
  // console.log(token);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    }
  };
  const response = await fetch(`${api_url}/api/employees`, requestOptions);
  return response;
};

// Export all the functions
const studentService = {
  createStudent,
  getAllStudents
};
export default studentService;
