
import React, { useState } from "react";
import studentService from "../../../services/student.service";
import { useAuth } from "../../../Contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [student_email, setEmail] = useState("");
  const [student_first_name, setFirstName] = useState("");
  const [student_last_name, setLastName] = useState("me");
  const [student_phone, setPhoneNumber] = useState("000000000");
  const [student_password, setPassword] = useState("");
  const [active_student, setActive_Student] = useState(1);
  const [company_role_id, setCompany_role_id] = useState(1);

  // Errors
  const [emailError, setEmailError] = useState("");
  const [firstNameRequired, setFirstNameRequired] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState("");

  // Loading state
  const [isLoading, setIsLoading] = useState(false);

  let loggedInStudentToken = "";

  const { student } = useAuth();
  if (student && student.student_token) {
    loggedInStudentToken = student.student_token;
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    let valid = true;

    if (!student_first_name) {
      setFirstNameRequired("First name is required");
      valid = false;
    } else {
      setFirstNameRequired("");
    }

    if (!student_email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!student_email.includes("@")) {
      setEmailError("Invalid email format");
    } else {
      const regex = /^\S+@\S+\.\S+$/;
      if (!regex.test(student_email)) {
        setEmailError("Invalid email format");
        valid = false;
      } else {
        setEmailError("");
      }
    }

    if (!student_password || student_password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!valid) {
      setIsLoading(false);
      return;
    }

    const formData = {
      student_email,
      student_first_name,
      student_last_name,
      student_phone,
      student_password,
      active_student,
      company_role_id
    };

    const newStudent = studentService.createStudent(
      formData,
      loggedInStudentToken
    );
    newStudent
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setServerError(data.error);
          setIsLoading(false);
        } else {
          setSuccess(true);
          setServerError("");
          setTimeout(() => {
            window.location.href = "/login";
          }, 2000);
        }
      })
      .catch(error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setServerError(resMessage);
        setIsLoading(false);
      });
  };

  return (
    <div className="bg-white bg-opacity-20 backdrop-blur-md rounded-lg p-10 shadow-lg">
      {serverError}
      <h2 className="text-3xl font-bold text-center text-white mb-8">
        Register
      </h2>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div>
          {firstNameRequired}
          <label
            htmlFor="username"
            className="block text-sm font-medium text-white"
          >
            Full Name
          </label>
          <input
            name="student_first_name"
            value={student_first_name}
            onChange={event => setFirstName(event.target.value)}
            type="text"
            id="username"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          {emailError}
          <label
            htmlFor="email"
            className="block text-sm font-medium text-white"
          >
            Email
          </label>
          <input
            type="email"
            name="student_email"
            value={student_email}
            onChange={event => setEmail(event.target.value)}
            id="email"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          {passwordError}
          <label
            htmlFor="password"
            className="block text-sm font-medium text-white"
          >
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="student_password"
              value={student_password}
              onChange={event => setPassword(event.target.value)}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="text-gray-400"
              />
            </div>
          </div>
        </div>
        <div>
          <button
            type="submit"
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${isLoading &&
              "opacity-50 cursor-not-allowed"}`}
            disabled={isLoading}
          >
            {isLoading
              ? <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v4l3.5-3.5L12 2V6a6 6 0 100 12v4l3.5-3.5L12 18v4a8 8 0 010-16z"
                  />
                </svg>
              : "Register"}
          </button>
        </div>
        <div className="text-center text-sm text-white">
          <p>
            Already have an account?{" "}
            <a
              href="#"
              className="font-medium text-indigo-600 hover:text-indigo-500"
              onClick={() => window.location.reload()}
            >
              Login
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Register;
