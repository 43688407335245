// import React from "react";
// import { Link } from "react-router-dom";
// import logo from "../../../assets/images/logo.png";
// import loginService from "../../../services/login.service";
// import { useAuth } from "../../../Contexts/AuthContext";

// function Header(props) {
//   const { isLogged, setIsLogged, student } = useAuth();

//   const logOut = () => {
//     loginService.logOut();
//     setIsLogged(false);
//   };

//   return (
//     <div>
//       <header className="bg-white shadow-md py-4 fixed top-0 w-full z-10">
//         <div className="container mx-auto px-4 flex justify-between items-center">
//           <div className="flex items-center">
//             <a href="/" className="mr-4">
//               <img src={logo} alt="EduEthiopia Logo" className="h-10 w-auto" />
//             </a>
//           </div>
//           <div className="flex items-center space-x-4">
//             {isLogged
//               ? <div className="flex items-center space-x-2">
//                   <nav className="hidden md:flex items-center space-x-4">
//                     <Link to="/" className="hover:text-blue-500">
//                       Home
//                     </Link>
//                     <Link to="/onlineexam" className="hover:text-blue-500">
//                       PastExam
//                     </Link>
//                     <Link to="/onlineexam" className="hover:text-blue-500">
//                       OnlineExam
//                     </Link>
//                     <Link to="#" className="hover:text-blue-500">
//                       Contact
//                     </Link>
//                   </nav>
//                   <div className="flex items-center space-x-2">
//                     <span className="text-sm font-medium text-gray-700 hidden md:inline">
//                       {student.student_first_name}
//                     </span>
//                     <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
//                       <span className="text-sm text-gray-700">
//                         {student.student_first_name.charAt(0)}
//                       </span>
//                     </div>
//                   </div>
//                   <Link
//                     to="/"
//                     className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
//                     onClick={logOut}
//                   >
//                     Log out
//                   </Link>
//                 </div>
//               : <Link
//                   to="/login"
//                   className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
//                 >
//                   Login
//                 </Link>}
//           </div>
//         </div>
//       </header>
//       <div className="pt-16">
//         {/* The rest of your page content goes here */}
//       </div>
//     </div>
//   );
// }

// export default Header;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import for animation
import logo from "../../../assets/images/logo.png";
import loginService from "../../../services/login.service";
import { useAuth } from "../../../Contexts/AuthContext";

function Header() {
  const { isLogged, setIsLogged, student } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false); // State to toggle menu

  const logOut = () => {
    loginService.logOut();
    setIsLogged(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); // Close the dropdown menu
  };

  return (
    <div>
      <header className="bg-white shadow-md py-4 fixed top-0 w-full z-10">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <div className="flex items-center">
            <a href="/" className="mr-4">
              <img src={logo} alt="EduEthiopia Logo" className="h-10 w-auto" />
            </a>
          </div>

          <div className="flex items-center space-x-4">
            {isLogged && (
              <div className="md:hidden flex items-center space-x-2">
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-gray-700">
                    Welcome {student.student_first_name}
                  </span>
                  <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-sm text-gray-700">
                      {student.student_first_name.charAt(0)}
                    </span>
                  </div>
                </div>
              </div>
            )}
            
            {/* Hamburger Icon for small screens */}
            <button
              className="md:hidden focus:outline-none"
              onClick={toggleMenu}
            >
              <svg
                className="w-6 h-6 text-gray-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>

            <nav className="hidden md:flex items-center space-x-4">
              {isLogged && (
                <>
                  <Link to="/" className="hover:text-blue-500">
                    Home
                  </Link>
                  <Link to="/online-test" className="hover:text-blue-500">
                    PastExam
                  </Link>
                  <Link to="/onlineexam" className="hover:text-blue-500">
                    OnlineExam
                  </Link>
                  <Link to="/contact" className="hover:text-blue-500">
                    Contact
                  </Link>
                </>
              )}
            </nav>
            {isLogged ? (
              <div className="hidden md:flex items-center space-x-2">
                <span className="text-sm font-medium text-gray-700">
                  {student.student_first_name}
                </span>
                <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                  <span className="text-sm text-gray-700">
                    {student.student_first_name.charAt(0)}
                  </span>
                </div>
                <Link
                  to="/"
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
                  onClick={logOut}
                >
                  Log out
                </Link>
              </div>
            ) : (
              
              <>
                <Link
                    to="/"
                    className="hidden md:inline block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Home
                  </Link>
                <Link
                    to="contact"
                    className="hidden md:inline block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Contact
                  </Link>
                <Link
                to="/login"
                className="hidden md:inline bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
              >
                Login
              </Link>
                
                  </>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {menuOpen && (
            <motion.nav
              className="md:hidden bg-white shadow-lg absolute left-[70%]" // Positioned at 30% from the right
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {isLogged ? (
                <div className="px-4 pb-4 space-y-2">
                  <Link
                    to="/"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Home
                  </Link>
                  <Link
                    to="/onlineexam"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    PastExam
                  </Link>
                  <Link
                    to="/onlineexam"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    OnlineExam
                  </Link>
                  <Link
                    to="/contact"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Contact
                  </Link>
                  <div className="items-center space-x-2 mt-4">
                    
        
                    <Link
                      to="/"
                      className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition-colors w-full text-center"
                      onClick={() => {
                        logOut();
                        closeMenu(); // Close menu on logout
                      }}
                    >
                      Log out
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                <Link
                    to="/"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Home
                  </Link>
                <Link
                    to="/contact"
                    className="block text-gray-700 hover:text-blue-500"
                    onClick={closeMenu} // Close menu on click
                  >
                    Contact
                  </Link>
                <Link
                  to="/login"
                  className="block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors w-full text-center"
                  onClick={closeMenu} // Close menu on click
                >
                  Login
                </Link>
                
                  </>
              )}
            </motion.nav>
          )}
        </AnimatePresence>
      </header>
      <div className="pt-16">
        {/* The rest of your page content goes here */}
      </div>
    </div>
  );
}

export default Header;
