// // Import the necessary components 
// import React, { useState, useEffect } from "react";
// import { Table, Button } from 'react-bootstrap';
// // Import the auth hook  
// import { useAuth } from "../../../../Contexts/AuthContext";
// // Import the date-fns library 
// import { format } from 'date-fns'; // To properly format the date on the table 
// // Import the getAllstudents function  
// import studentService from "../../../../services/student.service";

// // Create the studentsList component 
// const StudentsList = () => {
//   // Create all the states we need to store the data
//   // Create the students state to store the students data  
//   const [students, setStudents] = useState([]);
//   // A state to serve as a flag to show the error message 
//   const [apiError, setApiError] = useState(false);
//   // A state to store the error message 
//   const [apiErrorMessage, setApiErrorMessage] = useState(null);
//   // To get the logged in student token
//   const { student } = useAuth();
//   let token = null; // To store the token 
//   if (student) {
//     token = student.student_token;
//   }

//   useEffect(() => {
//     // Call the getAllstudents function 
//     const allStudents = studentService.getAllStudents(token);
//     allStudents.then((res) => {
//       if (!res.ok) {
//         console.log(res.status);
//         setApiError(true);
//         if (res.status === 401) {
//           setApiErrorMessage("Please login again");
//         } else if (res.status === 403) {
//           setApiErrorMessage("You are not authorized to view this page");
//         } else {
//           setApiErrorMessage("Please try again later");
//         }
//       }
//       return res.json()
//     }).then((data) => {
//       if (data.data.length !== 0) {
//         setStudents(data.data)
//       }

//     }).catch((err) => {
//       // console.log(err);
//     })
//   }, []);

//   return (
//    <>
//   {apiError ? (
//     <section className="py-8 bg-gray-100">
//       <div className="container mx-auto">
//         <div className="text-center">
//           <h2 className="text-2xl font-bold">{apiErrorMessage}</h2>
//         </div>
//       </div>
//     </section>
//   ) : (
//     <>
//       <section className="py-8 bg-gray-100">
//         <div className="container mx-auto">
//           <div className="text-center mb-4">
//             <h2 className="text-2xl font-bold">Students List</h2>
//           </div>
//           <div className="overflow-x-auto">
//             <table className="min-w-full bg-white border border-gray-200">
//               <thead>
//                 <tr>
//                   <th className="py-2 px-4 border-b">Active</th>
//                   <th className="py-2 px-4 border-b">First Name</th>
//                   <th className="py-2 px-4 border-b">Last Name</th>
//                   <th className="py-2 px-4 border-b">Email</th>
//                   <th className="py-2 px-4 border-b">Phone</th>
//                   <th className="py-2 px-4 border-b">Role</th>
//                   <th className="py-2 px-4 border-b">Edit/Delete</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {students.map((student) => (
//                   <tr key={student.student_id}>
//                     <td className="py-2 px-4 border-b">{student.active_student ? "Yes" : "No"}</td>
//                     <td className="py-2 px-4 border-b">{student.student_first_name}</td>
//                     <td className="py-2 px-4 border-b">{student.student_last_name}</td>
//                     <td className="py-2 px-4 border-b">{student.student_email}</td>
//                     <td className="py-2 px-4 border-b">{student.student_phone}</td>
//                     <td className="py-2 px-4 border-b">{student.company_role_name}</td>
//                     <td className="py-2 px-4 border-b">
//                       <div className="flex justify-center space-x-2">
//                         <span className="text-blue-500 cursor-pointer">edit</span>
//                         <span className="text-red-500 cursor-pointer">delete</span>
//                       </div>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </section>
//     </>
//   )}
// </>

//   );
// }

// // Export the studentsList component 
// export default StudentsList;
// Import the necessary components 
import React, { useState, useEffect } from "react";
// Import the auth hook  
import { useAuth } from "../../../../Contexts/AuthContext";
// Import the getAllStudents function  
import studentService from "../../../../services/student.service";

// Create the StudentsList component 
const StudentsList = () => {
  // Create all the states we need to store the data
  // Create the students state to store the students data  
  const [students, setStudents] = useState([]);
  // A state to serve as a flag to show the error message 
  const [apiError, setApiError] = useState(false);
  // A state to store the error message 
  const [apiErrorMessage, setApiErrorMessage] = useState(null);
  // To get the logged in student token
  const { student } = useAuth();
  let token = null; // To store the token 
  if (student) {
    token = student.student_token;
  }

  useEffect(() => {
    // Call the getAllStudents function 
    const allStudents = studentService.getAllStudents(token);
    allStudents.then((res) => {
      if (!res.ok) {
        console.log(res.status);
        setApiError(true);
        if (res.status === 401) {
          setApiErrorMessage("Please login again");
        } else if (res.status === 403) {
          setApiErrorMessage("You are not authorized to view this page");
        } else {
          setApiErrorMessage("Please try again later");
        }
      }
      return res.json();
    }).then((data) => {
      if (data.data.length !== 0) {
        setStudents(data.data);
      }
    }).catch((err) => {
      // console.log(err);
    });
  }, [token]);

  return (
    <>
      {apiError ? (
        <section className="py-8 bg-gray-100">
          <div className="container mx-auto">
            <div className="text-center">
              <h2 className="text-2xl font-bold">{apiErrorMessage}</h2>
            </div>
          </div>
        </section>
      ) : (
        <section className="py-8 bg-gray-100">
          <div className="container mx-auto">
            <div className="text-center mb-4">
              <h2 className="text-2xl font-bold">Students List</h2>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b">Active</th>
                    <th className="py-2 px-4 border-b">First Name</th>
                    <th className="py-2 px-4 border-b">Last Name</th>
                    <th className="py-2 px-4 border-b">Email</th>
                    <th className="py-2 px-4 border-b">Phone</th>
                    <th className="py-2 px-4 border-b">Role</th>
                    <th className="py-2 px-4 border-b">Edit/Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr key={student.student_id}>
                      <td className="py-2 px-4 border-b">{student.active_student ? "Yes" : "No"}</td>
                      <td className="py-2 px-4 border-b">{student.student_first_name}</td>
                      <td className="py-2 px-4 border-b">{student.student_last_name}</td>
                      <td className="py-2 px-4 border-b">{student.student_email}</td>
                      <td className="py-2 px-4 border-b">{student.student_phone}</td>
                      <td className="py-2 px-4 border-b">{student.company_role_name}</td>
                      <td className="py-2 px-4 border-b">
                        <div className="flex justify-center space-x-2">
                          <span className="text-blue-500 cursor-pointer">edit</span>
                          <span className="text-red-500 cursor-pointer">delete</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

// Export the StudentsList component 
export default StudentsList;
