
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FaAngleDoubleRight, FaBookOpen, FaClock, FaQuestionCircle } from "react-icons/fa";
import Lottie from "lottie-react";
import loadingAnimation from "../Lottia/loading.json";
import questionService from "../../../services/question.service";
import { useAuth } from "../../../Contexts/AuthContext";
import { useSpring, animated } from "@react-spring/web";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// Your component code here

const Subject = ({ startTest }) => {
  const { student } = useAuth() || {};
  const [isLoading, setIsLoading] = useState(true);
  const [subjectData, setSubjectData] = useState([]);
  const [error, setError] = useState("");
  const [progressValues, setProgressValues] = useState([]);

  const images = useMemo(
    () => [
      "https://plus.unsplash.com/premium_photo-1676226342566-3fb5f2e1f7ae?q=80&w=1632&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://plus.unsplash.com/premium_photo-1661430659143-ffbb5ce2b6a7?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8Q2hlbWlzdHJ5fGVufDB8fDB8fHww",
      "https://media.istockphoto.com/id/953006834/photo/science-math-chemistry-equations.webp?b=1&s=170667a&w=0&k=20&c=C7j2-2fPhX7328EueqIYiKNz7rs5-bvw0SF_bfjHaBg=",
      "https://media.istockphoto.com/id/683574842/photo/exam.jpg?s=612x612&w=0&k=20&c=FPqiMU0CrZt32cCsNyUZs7YghiBuzvOLoBfdKbx5Bik=",
      "https://i0.wp.com/www.enggwave.com/wp-content/uploads/2021/01/Online-Aptitude-Test.png?fit=900%2C600&ssl=1",
      "https://images.shiksha.com/mediadata/images/articles/1628666138phpFXnn99.jpeg"
    ],
    []
  );

  const grades = useMemo(
    () => ["Biology", "Chemistry", "Maths", "English", "Aptitude", "Physics"],
    []
  );

  const questionCounts = useMemo(() => [100, 100, 100, 100, 100, 100], []);

  const times = useMemo(
    () => [
      "180 mins",
      "180 mins",
      "180 mins",
      "180 mins",
      "180 mins",
      "180 mins"
    ],
    []
  );

  const fetchQuestions = useCallback(
    async () => {
      if (!student) return;
      setIsLoading(true);
      try {
        const response = await questionService.getUserScore(student.student_id);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success" && Array.isArray(data.data)) {
          setSubjectData(data.data);

          // Map backend scores to progressValues
          const updatedProgressValues = grades.map(grade => {
            const score = data.data.find(item => item.grade === grade);
            return score ? score.score : 0;
          });
          setProgressValues(updatedProgressValues);
        } else {
          setError("No questions available.");
          setProgressValues(grades.map(() => 0));
        }
      } catch (err) {
        console.error("Error fetching questions:", err);
        setError("Failed to load questions. Please try again later.");
        setProgressValues(grades.map(() => 0));
      } finally {
        setIsLoading(false);
      }
    },
    [student, grades]
  );

  useEffect(
    () => {
      fetchQuestions();
    },
    [fetchQuestions]
  );

  const animationProps = useSpring({
    from: { opacity: 0, transform: "scale(0.95)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 500 }
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Lottie
          animationData={loadingAnimation}
          style={{ width: 150, height: 150 }}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center">
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto">
      {/* Swiper carousel for small screens */}
      <div className="block sm:hidden">
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={1.2}
          centeredSlides={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          pagination={{ clickable: true, dynamicBullets: true }}
          loop={true}
        >
          {images.map((image, index) =>
            <SwiperSlide key={index}>
              <animated.div
                style={animationProps}
                className="max-w-xs rounded w-76 overflow-hidden shadow-lg m-4 transform transition duration-500 hover:scale-105 bg-white hover:shadow-light"
              >
                <div className="relative">
                  <img
                    className="w-full h-38 object-cover"
                    src={image}
                    alt={`Sample ${index + 1}`}
                  />
                  <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-50" />
                </div>
                <div className="px-4 py-2">
                  <div className="font-bold text-lg mb-2 text-center animate-fadeIn text-black flex items-center justify-center">
                    {grades[index]}
                    <FaBookOpen className="inline-block ml-2" />
                  </div>
                  <p className="text-gray-800 text-sm animate-fadeIn">
                    This is 2016 moe Entrance exam
                  </p>
                  <div className="flex items-center justify-between mt-2">
                    <div className="flex items-center text-gray-600">
                      <FaClock className="mr-1" />
                      <span className="text-sm">
                        {times[index]}
                      </span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <FaQuestionCircle className="mr-1" />
                      <span className="text-sm">
                        {questionCounts[index]} questions
                      </span>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-2">
                  <div className="w-full bg-gray-200 rounded-full h-4 mb-4 relative">
                    <div
                      className="bg-yellow-500 h-4 rounded-full"
                      style={{
                        width: `${progressValues[index] || 0}%`,
                        transition: "width 0.5s ease-in-out"
                      }}
                    >
                      <animated.div
                        style={{
                          ...animationProps,
                          position: "absolute",
                          top: "50%",
                          left: `${progressValues[index] || 0}%`,
                          transform: "translateX(-50%) translateY(-50%)",
                          fontSize: "0.75rem",
                          color: "#000",
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        {progressValues[index] || 0}%
                      </animated.div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center px-4 pb-4">
                  <button
                    onClick={() => startTest(grades[index])}
                    className="bg-blue-500 text-white font-bold py-6 px-10 rounded-md text-lg relative overflow-hidden"
                  >
                    <span className="absolute inset-0 flex items-center justify-center">
                      <span>Start</span>
                      <FaAngleDoubleRight className="relative ml-1 animate-ping hover:animate-none transition-all " />
                    </span>
                  </button>
                </div>
              </animated.div>
            </SwiperSlide>
          )}
        </Swiper>
      </div>

      {/* Regular grid layout for larger screens */}
      <div className="hidden sm:flex flex-wrap ml-1 mx-auto content-center items-center justify-center">
        {images.map((image, index) =>
          <animated.div
            key={index}
            style={animationProps}
            className="max-w-xs rounded w-76 overflow-hidden shadow-lg m-4 transform transition duration-500 hover:scale-105 bg-white hover:shadow-light"
          >
            <div className="relative">
              <img
                className="w-full h-38 object-cover"
                src={image}
                alt={`Sample ${index + 1}`}
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black to-transparent opacity-50" />
            </div>
            <div className="px-4 py-2">
              <div className="font-bold text-lg mb-2 text-center animate-fadeIn text-black flex items-center justify-center">
                {grades[index]}
                <FaBookOpen className="inline-block ml-2" />
              </div>
              <p className="text-gray-800 text-sm animate-fadeIn">
                This is 2016 moe Entrance exam
              </p>
              <div className="flex items-center justify-between mt-2">
                <div className="flex items-center text-gray-600">
                  <FaClock className="mr-1" />
                  <span className="text-sm">
                    {times[index]}
                  </span>
                </div>
                <div className="flex items-center text-gray-600">
                  <FaQuestionCircle className="mr-1" />
                  <span className="text-sm">
                    {questionCounts[index]} questions
                  </span>
                </div>
              </div>
            </div>
            <div className="px-4 py-2">
              <div className="w-full bg-gray-200 rounded-full h-4 mb-4 relative">
                <div
                  className="bg-yellow-500 h-4 rounded-full"
                  style={{
                    width: `${progressValues[index] || 0}%`,
                    transition: "width 0.5s ease-in-out"
                  }}
                >
                  <animated.div
                    style={{
                      ...animationProps,
                      position: "absolute",
                      top: "50%",
                      left: `${progressValues[index] || 0}%`,
                      transform: "translateX(-50%) translateY(-50%)",
                      fontSize: "0.75rem",
                      color: "#000",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {progressValues[index] || 0}%
                  </animated.div>
                </div>
              </div>
            </div>
            <div className="flex justify-center px-4 pb-4">
              <button
                onClick={() => startTest(grades[index])}
                className="bg-blue-500 text-white font-bold py-6 px-10 rounded-md text-lg relative overflow-hidden"
              >
                <span className="absolute inset-0 flex items-center justify-center">
                  <span>Start</span>
                  <FaAngleDoubleRight className="relative ml-1 animate-ping hover:animate-none transition-all " />
                </span>
              </button>
            </div>
          </animated.div>
        )}
      </div>
    </div>
  );
};

export default Subject;
