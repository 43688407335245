import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { FaChevronDown,  FaAngleDoubleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import question  from '../../../assets/images/question.png'
import past  from '../../../assets/images/past.png'
import user from '../../../assets/images/user.png'
function Landing() {
  const controls = useAnimation();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 100) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [scrollY, controls]);
  return (
    
      <>
  
  <link href="./output.css" rel="stylesheet" />
  <section
    className="bg-cover bg-center min-h-screen flex flex-col justify-center items-center"
    style={{
      backgroundImage: `url(${require('../../../assets/images/Layer.png')})`,
    }}
  >
    <div className="flex flex-col items-center text-center space-y-8">
      <motion.div
        className="shadow-lg p-6 rounded-lg bg-opacity-20 bg-gray-800"
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        whileHover={{ scale: 1.02 }}
      >
        <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl text-white">
          Education tool for
          <samp className="text-yellow-400 max-w-2xl mb-4 text-4xl ml-2 font-extrabold leading-none md:text-5xl xl:text-6xl">
            Student's
          </samp>
        </h1>
      </motion.div>
      <div className="flex flex-col items-center space-y-4 m">
        <Link
          to="/login"
          className="inline-flex mr-2 items-center justify-center px-5 mt-32 py-3 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 focus:ring-blue-900"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Join Us
          <FaAngleDoubleRight className='ml-2 animate-ping'/>
            
        </Link>
        <motion.a
          href="#about"
          className="inline-flex items-center justify-center bg-yellow-400 px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          About
          <FaChevronDown className="w-5 h-5 ml-2 -mr-1" />
        </motion.a>
      </div>
    </div>
  </section>
 <section class="student-course py-12 mt-6">
  <div class="container mx-auto mt-10 px-4">
    <div class="course-widget">
      <div class="flex flex-wrap">
        <div class="w-full sm:w-1/2 lg:w-1/3 mb-8 px-4">
          <div class="course-full-width">
            <div class="blur-border course-radius flex items-center justify-center bg-white p-6 shadow-lg" data-aos="fade-up">
              <div class="online-course flex items-center">
                <div class="course-img">
                  <img src={question} alt="Img"/>
                </div>
                <div class="course-inner-content ml-4">
                  <h4 class="text-3xl font-bold"><span>10</span>K</h4>
                  <p class="text-gray-600">Online Questions</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/3 mb-8 px-4">
          <div class="course-full-width">
            <div class="blur-border course-radius flex items-center justify-center bg-white p-6 shadow-lg" data-aos="fade-up">
              <div class="online-course flex items-center">
                <div class="course-img">
                  <img src={past} alt="Img"/>
                </div>
                <div class="course-inner-content ml-4">
                  <h4 class="text-3xl font-bold"><span>200</span>k+</h4>
                  <p class="text-gray-600">Past Exam questions</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full sm:w-1/2 lg:w-1/3 mb-8 px-4">
          <div class="course-full-width">
            <div class="blur-border course-radius flex items-center justify-center bg-white p-6 shadow-lg" data-aos="fade-up">
              <div class="online-course flex items-center">
                <div class="course-img">
                  <img src={user} alt="Img"/>
                </div>
                <div class="course-inner-content ml-4">
                  <h4 class="text-3xl font-bold"><span>600</span>+</h4>
                  <p class="text-gray-600">Users</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


<section class="new-course container py-12">
  <div class=" mx-auto px-4">
    <div class="section-header mb-6" data-aos="fade-up">
      <div class="section-sub-head mb-4">
        <span class="text-sm text-gray-500">Our Service</span>
        <h2 class="text-3xl font-bold"></h2>
      </div>
      <div class="all-btn all-category">
        {/* <a href="-list.html" class="btn bg-blue-500 text-white py-2 px-4 rounded">All Courses</a> */}
      </div>
    </div>
    <div class="course-feature">
      <div class="flex flex-wrap">
        {/* <!-- Course Card 1 --> */}
        <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="course-box" data-aos="fade-up">
            <div class="product bg-white shadow-lg rounded-lg overflow-hidden">
              <div class="product-img relative">
                <a href="-details.html">
                  {/* <!-- Image goes here --> */}
                </a>
                <div class="price absolute top-0 right-0 bg-red-500 text-white py-1 px-3 rounded-bl-lg">
                  {/* <!-- Price goes here --> */}
                </div>
              </div>
              <div class="product-content p-4">
                <div class="course-group flex justify-between">
                  <div class="course-group-img flex">
                    <a href="/instructor-profile.html">
                      {/* <!-- Instructor Image goes here --> */}
                    </a>
                    <div class="course-name ml-4">
                      <h4 class="font-bold text-lg"><a href="/instructor-profile.html">Grade 12</a></h4>
                      <p class="text-sm text-gray-500">Entrance exam</p>
                    </div>
                  </div>
                  <div class="course-share flex items-center justify-center">
                    <a href="#"><i class="fa-regular fa-heart text-gray-400"></i></a>
                  </div>
                </div>
                <h3 class="title instructor-text mt-4"><a href="-details.html" class="text-xl font-semibold text-gray-800">Practice Past Exam in Online</a></h3>
                <div class="course-info mt-4 flex justify-between items-center">
                  <div class="rating-img flex items-center text-gray-500">
                    <p class="ml-2">last past 5+ years</p>
                  </div>
                  <div class="course-view flex items-center text-gray-500">
                    <p class="ml-2">1000 Questions</p>
                  </div>
                </div>
                <div class="mt-4 flex justify-between items-center">
                  <div class="rating text-yellow-400 flex items-center">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star text-gray-300"></i>
                    <span class="ml-2 text-gray-600"><span>4.0</span> (15)</span>
                  </div>
                  <div class="all-btn all-category">
                    <a href="/login" class="bg-blue-500 text-white py-2 px-4 rounded">Free</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="course-box" data-aos="fade-up">
            <div class="product bg-white shadow-lg rounded-lg overflow-hidden">
              <div class="product-img relative">
                <a href="-details.html">
                  {/* <!-- Image goes here --> */}
                </a>
                <div class="price absolute top-0 right-0 bg-red-500 text-white py-1 px-3 rounded-bl-lg">
                  {/* <!-- Price goes here --> */}
                </div>
              </div>
              <div class="product-content p-4">
                <div class="course-group flex justify-between">
                  <div class="course-group-img flex">
                    <a href="/instructor-profile.html">
                      {/* <!-- Instructor Image goes here --> */}
                    </a>
                    <div class="course-name ml-4">
                      <h4 class="font-bold text-lg"><a href="/instructor-profile.html">Grade 8</a></h4>
                      <p class="text-sm text-gray-500">Matric Exam</p>
                    </div>
                  </div>
                  <div class="course-share flex items-center justify-center">
                    <a href="#"><i class="fa-regular fa-heart text-gray-400"></i></a>
                  </div>
                </div>
                <h3 class="title instructor-text mt-4"><a href="-details.html" class="text-xl font-semibold text-gray-800">Practice Past Exam in Online</a></h3>
                <div class="course-info mt-4 flex justify-between items-center">
                  <div class="rating-img flex items-center text-gray-500">
                    <p class="ml-2">last past 5+ years</p>
                  </div>
                  <div class="course-view flex items-center text-gray-500">
                    <p class="ml-2">1000 Questions</p>
                  </div>
                </div>
                <div class="mt-4 flex justify-between items-center">
                  <div class="rating text-yellow-400 flex items-center">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star text-gray-300"></i>
                    <span class="ml-2 text-gray-600"><span>4.0</span> (15)</span>
                  </div>
                  <div class="all-btn all-category">
                    <Link to={"/comingsoo"}class="bg-blue-500 text-white py-2 px-4 rounded">Free</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8">
          <div class="course-box" data-aos="fade-up">
            <div class="product bg-white shadow-lg rounded-lg overflow-hidden">
              <div class="product-img relative">
                
                <div class="price absolute top-0 right-0 bg-red-500 text-white py-1 px-3 rounded-bl-lg">
                  {/* <!-- Price goes here --> */}
                </div>
              </div>
              <div class="product-content p-4">
                <div class="course-group flex justify-between">
                  <div class="course-group-img flex">
                    <a href="/instructor-profile.html">
                      {/* <!-- Instructor Image goes here --> */}
                    </a>
                    <div class="course-name ml-4">
                      <h4 class="font-bold text-lg"><a href="/instructor-profile.html">Grade 6</a></h4>
                      <p class="text-sm text-gray-500">Ministry Exam</p>
                    </div>
                  </div>
                  <div class="course-share flex items-center justify-center">
                    {/* <a href="#"><i class="fa-regular fa-heart text-gray-400"></i></a> */}
                  </div>
                </div>
                <h3 class="title instructor-text mt-4"><a href="-details.html" class="text-xl font-semibold text-gray-800">Practice Past Exam in Online</a></h3>
                <div class="course-info mt-4 flex justify-between items-center">
                  <div class="rating-img flex items-center text-gray-500">
                    <p class="ml-2">last past 5+ years</p>
                  </div>
                  <div class="course-view flex items-center text-gray-500">
                    <p class="ml-2">1000 Questions</p>
                  </div>
                </div>
                <div class="mt-4 flex justify-between items-center">
                  <div class="rating text-yellow-400 flex items-center">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star text-gray-300"></i>
                    <span class="ml-2 text-gray-600"><span>4.0</span> (15)</span>
                  </div>
                  <div class="all-btn all-category">
                    <a href="/checkout.html" class="bg-blue-500 text-white py-2 px-4 rounded">Free</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 

        {/* <!-- Repeat for other courses --> */}
        {/* <!-- Course Card 2 --> */}
        

      </div>
    </div>
  </div>
</section>
<section class="new-course container py-12">
  <div class=" mx-auto px-4">
    <motion.div
      className="max-w-screen-md mb-8 lg:mb-16 text-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <h2 className="mb-4 text-4xl md:text-5xl lg:text-6xl font-extrabold text-white tracking-wide">
        <motion.div
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          Future Courses 
        </motion.div>
        <motion.div className='mb-4 text-2xl md:text-3xl lg:text-4xl font-extrabold text-white tracking-wide'
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1, ease: "easeOut", delay: 0.5 }}
        >
          We try to highlight some of the most dominant courses in the coming era.
        </motion.div>
      </h2>
    </motion.div>
    <motion.div
      className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 "
      initial="hidden"
      animate="visible"
      variants={{
        hidden: {},
        visible: {
          transition: {
            staggerChildren: 0.3,
          },
        },
      }}
    >
      {[
         {
    imgSrc: require('../../../assets/images/Student_Follow.png'),
    title: "Software Engineering",
    description: "With the rapid expansion of Ethiopia's tech industry, Software Engineering is becoming one of the most sought-after fields. This course equips students with the skills to design, develop, and maintain software systems, essential for driving innovation in various sectors."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Computer Science",
    description: "Computer Science forms the backbone of modern technology. This course covers a broad range of topics including algorithms, data structures, artificial intelligence, and cybersecurity, preparing students to tackle complex problems in the digital age."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Medicine",
    description: "As Ethiopia continues to improve its healthcare infrastructure, Medicine remains a critical field. This course provides comprehensive training for future doctors, focusing on both the science and the compassionate care necessary to meet the country's healthcare needs."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Management",
    description: "Management skills are essential for the effective operation of any business or organization. This course teaches strategic planning, leadership, and decision-making, enabling graduates to lead teams and drive organizational success in various industries."
  },
      {
        imgSrc: require('../../../assets/images/Layer.png'),
        title: "Software Engineering",
    description: "With the rapid expansion of Ethiopia's tech industry, Software Engineering is becoming one of the most sought-after fields. This course equips students with the skills to design, develop, and maintain software systems, essential for driving innovation in various sectors."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Computer Science",
    description: "Computer Science forms the backbone of modern technology. This course covers a broad range of topics including algorithms, data structures, artificial intelligence, and cybersecurity, preparing students to tackle complex problems in the digital age."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Medicine",
    description: "As Ethiopia continues to improve its healthcare infrastructure, Medicine remains a critical field. This course provides comprehensive training for future doctors, focusing on both the science and the compassionate care necessary to meet the country's healthcare needs."
  },
  {
    imgSrc: require('../../../assets/images/Layer.png'),
    title: "Management",
    description: "Management skills are essential for the effective operation of any business or organization. This course teaches strategic planning, leadership, and decision-making, enabling graduates to lead teams and drive organizational success in various industries."
  },
      ].map((item, index) => (
        <motion.div
          key={index}
          className="p-8 bg-white rounded-lg shadow-2xl hover:shadow-3xl transition-shadow duration-300 product bg-white shadow-lg rounded-lg overflow-hidden"
          whileHover={{ scale: 1.05 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="flex justify-center items-center mx-auto mb-4 w-32 h-32 lg:w-40 lg:h-40 overflow-hidden">
            <img src={item.imgSrc} alt={item.title} className="w-full h-full object-cover" />
          </div>
          <h3 className="mb-2 text-xl font-bold text-gray-900 text-center">{item.title}</h3>
          <p className="text-gray-800 text-justify">{item.description}</p>
        </motion.div>
      ))}
    </motion.div>
  </div>
</section>




  <section id='about' className="bg-white  :bg-gray-900">
    <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
      <div className="font-light text-gray-500 sm:text-lg  :text-gray-400">
        <h2 className="mb-4 text-4xl font-extrabold text-gray-900  :text-white">
          We didn't reinvent the wheel
        </h2>
        <p className="mb-4">
          We are strategists, designers and developers. Innovators and problem
          solvers. Small enough to be simple and quick, but big enough to
          deliver the scope you want at the pace you need. Small enough to be
          simple and quick, but big enough to deliver the scope you want at the
          pace you need.
        </p>
        <p>
          We are strategists, designers and developers. Innovators and problem
          solvers. Small enough to be simple and quick.
        </p>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-8">
        <img
          className="w-full rounded-lg"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
          alt="office content 1"
        />
        <img
          className="mt-4 w-full lg:mt-10 rounded-lg"
          src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
          alt="office content 2"
        />
      </div>
    </div>
  </section>
  <section className="bg-gray-50  :bg-gray-900  :bg-gray-800">
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="max-w-screen-lg text-gray-500 sm:text-lg  :text-gray-400">
        <h2 className="mb-4 text-4xl font-bold text-gray-900  :text-white">
          Powering innovation at{" "}
          <span className="font-extrabold">200,000+</span> companies worldwide
        </h2>
        <p className="mb-4 font-light">
          Track work across the enterprise through an open, collaborative
          platform. Link issues across Jira and ingest data from other software
          development tools, so your IT support and operations teams have richer
          contextual information to rapidly respond to requests, incidents, and
          changes.
        </p>
        <p className="mb-4 font-medium">
          Deliver great service experiences fast - without the complexity of
          traditional ITSM solutions.Accelerate critical development work,
          eliminate toil, and deploy changes with ease.
        </p>
        <a
          href="#"
          className="inline-flex items-center font-medium text-blue-600 hover:text-blue-800 dark:text-blue-500 dark:hover:text-blue-700"
        >
          Learn more
          <svg
            className="ml-1 w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  </section>
  <section className="bg-white dark:bg-gray-900">
    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-sm text-center">
        <h2 className="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">
          Start your free trial today
        </h2>
        <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
          Try Flowbite Platform for 30 days. No credit card required.
        </p>
        <a
          href="#"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Free trial for 30 days
        </a>
      </div>
    </div>
  </section>
  
</>


  )
}
export default Landing
