import React from "react";
import OnlineTest from "../components/Admin/OnlineTest";
import Year from "../components/Admin/Year";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Home() {
  return <div className="container mx-auto">
      <div className="">
        {/* Regular layout for larger screens */}
        <div className="">
          <div className="">
            <OnlineTest />
          </div>
          

          <div className="">
            <Year />
          </div>
        </div>
      </div>
    </div>;
}

export default Home;
