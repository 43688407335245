
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
// import Home from "./markup/pages/Home";
import Login from "./markup/pages/Login";
import AddQuestion from './markup/pages/admin/AddQuestion';
import Unauthorized from './markup/pages/Unauthorized';
// import Orders from './markup/pages/admin/Orders';
import Customers from './markup/pages/admin/Customers';
import Employees from './markup/pages/admin/Employees';
import FileSystem from './markup/components/Admin/FileSystemExel';
import OnlineTest from './markup/components/Admin/OnlineTest';
import AddStudent from './markup/pages/admin/AddStudent';
import Year from './markup/components/Admin/Year';
// import FileUpload from './markup/components/Admin/QustionInserting/FileUpload';
import Landing from './markup/components/Admin/Landing';
import Header from './markup/components/Header/Header';
import Footer from './markup/components/Footer/Footer';
import { useAuth } from "./Contexts/AuthContext";
import loadingAnimation from "./markup/components/Lottia/loading.json";
import "./assets/template_assets/css/style.css";
import "./assets/template_assets/css/responsive.css";
import "./assets/styles/custom.css";
import Lottie from "lottie-react";
import ComingSoon from './markup/pages/ComingSoon';
import AddQuestionPast from './markup/pages/admin/AddQuestionPast';
import Home from './markup/pages/Home';
import Contact from './markup/pages/Contact';
import OnlineTestAdmin from './markup/components/Admin/OnlineTestAdmin';

// Loading component
const Loading = () => (
  <div className="flex justify-center items-center h-screen">
    <Lottie
          animationData={loadingAnimation}
          style={{ width: 150, height: 150 }}
        />
  </div>
);

function App() {
  const { isLogged, isStudent, isAdmin } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate an async operation to check authentication
    const checkAuth = async () => {
      // Simulate a delay (you might check an API, local storage, etc.)
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLoading(false);
    };

    checkAuth();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {/* Conditionally render the Header if the user is logged in */}
      <Header />
      <Routes>
        {/* Default route based on role */}
        <Route path="/" element={
          isLogged ? 
            (isStudent ? <Navigate to="/online/" /> : <Navigate to="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0sl" />) :
            <Landing />
        } />
        <Route 
          path="/contact" 
          element={ <Contact/> }
        />
        {/* Routes restricted to students */}
        <Route 
          path="/online-test" 
          element={isStudent ? <Year /> : <Navigate to="/comingsoon" />}
        />
        <Route 
          path="/onlineexam" 
          element={isStudent ? <OnlineTest /> : <Navigate to="/comingsoon" />}
        />
        <Route 
          path="/online/" 
          element={isStudent ? <Home /> : <Navigate to="/comingsoon" />}
        />

        {/* Routes restricted to admins */}
        {isAdmin && (
          <>
            <Route path="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0q" element={<AddQuestionPast />} />
            <Route path="/admin/customers" element={<Customers />} />
            <Route path="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0sl" element={<Employees />} />
            <Route path="/filesystemexel" element={<FileSystem />} />
            <Route path="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0s" element={<AddStudent />} />
            <Route path="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN03" element={<AddQuestion />} />
            <Route path="/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN034" element={<OnlineTestAdmin/>} />

            
          </>
        )}

        {/* Common Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/comingsoon" element={<ComingSoon />} />

        {/* Redirect all other routes */}
        <Route 
          path="*" 
          element={<Navigate to={isLogged ? (isStudent ? "/online/" : "/yJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0OCwidXNlcl9lbWFpbCI6InN0b3JlQHN0sl") : "/comingsoon"} />} 
        />
      </Routes>
      {/* Conditionally render the Footer if the user is logged in */}
      <Footer />
    </>
  );
}

export default App;
// import React from 'react'
// import WordToExcelConverter from './WordToExcelConverter'
// import ExtractText from './ExtractText'

// function App() {
//   return (
//     <div>
//       <WordToExcelConverter/>
//       <ExtractText/>
//     </div>
    

//   )
// }

// export default App