import React from "react";
import { FaEnvelope, FaTelegramPlane, FaInstagram } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="max-w-4xl bg-gray-500 mx-auto p-6">
      <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>

      <div className="flex flex-col md:flex-row md:space-x-8 mb-8">
        <div className="flex-1 mb-4 md:mb-0">
          <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
          <form
            action="mailto:your-email@example.com" // Replace with your email
            method="post"
            encType="text/plain"
            className="space-y-4"
          >
            <div>
              <label htmlFor="name" className="block text-lg font-medium mb-1">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full p-3 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-lg font-medium mb-1">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full p-3 border border-gray-300 rounded-md"
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-lg font-medium mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="6"
                className="w-full p-3 border border-gray-300 rounded-md"
                placeholder="Your message here..."
                required
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Send
            </button>
          </form>
        </div>

        <div className="flex-1">
          <h2 className="text-2xl font-semibold mb-4">Follow Us</h2>
          <div className="flex space-x-4">
            <a
              href="mailto:your-email@example.com"
              className="text-white hover:text-gray-800 transition-colors duration-300"
              aria-label="Email"
            >
              <FaEnvelope className="text-3xl" />
            </a>
            <a
              href="https://t.me/your_telegram_channel"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:text-blue-700 transition-colors duration-300"
              aria-label="Telegram"
            >
              <FaTelegramPlane className="text-3xl" />
            </a>
            <a
              href="https://www.instagram.com/your_instagram_handle"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 hover:text-pink-700 transition-colors duration-300"
              aria-label="Instagram"
            >
              <FaInstagram className="text-3xl" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
