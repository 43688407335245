import React from "react";
// Import the auth hook 
import { useAuth } from "../../../Contexts/AuthContext";
// Import the login form component 
import LoginForm from '../../components/LoginForm/LoginForm';
// Import the admin menu component
import AdminMenu from "../../components/Admin/MangerMenu/MangerMenu";
// Import the EmployeesList component 
import EmployeesList from "../../components/Admin/StudentList/StudentList";
function Employees() {
  // Destructure the auth hook 
  const { isLogged, isAdmin } = useAuth();

  if (isLogged) {

    console.log("Kebede");

    if (isAdmin) {
      return <div>
          <div class="container mx-auto admin-pages">
            <div class="flex flex-wrap">
              <div class="w-full md:w-3/12 admin-left-side">
                <AdminMenu />
              </div>
              <div class="w-full md:w-9/12 admin-right-side">
                <EmployeesList />
              </div>
            </div>
          </div>
        </div>;
    } else {
      return (
        <div>
          <h1>You are not authorized to access this page</h1>
        </div>
      );
    }
  } else {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }

}

export default Employees; 