import React, { useState } from "react";
import { FaFlag} from "react-icons/fa";
function Question({ question, index, onAnswerChange, selectedAnswer, onFlagToggle, isFlagged, showResults }) {
  const choices = {
    A: question.choiceA,
    B: question.choiceB,
    C: question.choiceC,
    D: question.choiceD,
  };
  // const api_url = process.env.REACT_APP_API_URL;
  const api_url = "https://ba.ethioedutools.com";

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return <div className="relative p-4 border rounded-lg bg-white shadow-lg mb-24">
      <button onClick={() => onFlagToggle(index)} className={`absolute top-2 right-2 p-2 border rounded ${isFlagged ? "bg-red-500 text-white" : "bg-gray-200 text-black"}`}>
        <FaFlag size={16} />
      </button>
      <h2 className="text-xl font-bold mb-4">
        {index + 1}: {question.question}
      </h2>
      <div>
        {/* Render the image */}
        {question.image && <img className=" h-60 w-full rounded-lg mt-3 cursor-pointer" src={`${api_url}/${question.image}`} alt="question image" onClick={handleImageClick} />}

        {/* Modal for showing the image in a larger size on the right side */}
        {isModalOpen && <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-end items-center" onClick={closeModal}>
            <div className="relative mt-36 w-4/5 h-full flex justify-end">
              <div className="bg-white p-4 rounded-lg shadow-lg" style={{ width: "80%", height: "80%" }}>
                <button className="absolute top-2 right-2 text-gray-700 text-2xl font-bold" onClick={closeModal}>
                  &times;
                </button>
                <img className="w-full h-full object-contain rounded-lg" src={`${api_url}/${question.image}`} alt="question enlarged" />
              </div>
            </div>
          </div>}
      </div>

      <ul className="list-none">
        {Object.keys(choices).map(key =>
          <li key={`${question.id}-${key}`} className="mb-2">
            <label className="flex items-center">
              <input
                type="radio"
                name={question.id}
                value={key}
                checked={selectedAnswer === key}
                onChange={() => onAnswerChange(question.id, key)}
                className="mr-2"
                disabled={showResults}
              />
              <span
                className={`flex-1 p-2 rounded ${showResults
                  ? key === question.correctAnswer
                    ? "bg-green-200"
                    : selectedAnswer === key ? "bg-red-200" : ""
                  : ""}`}
              >
                {choices[key]}
              </span>
              {showResults &&
                selectedAnswer === key &&
                <span className="ml-2">
                  {key === question.correctAnswer ? "✓" : "✗"}
                </span>}
            </label>
          </li>
        )}
      </ul>
      {showResults && <div className={`mt-4 p-2 rounded ${selectedAnswer === question.correctAnswer ? "bg-green-200" : "bg-red-200"}`}>
          <strong>Your Answer: </strong>
          {choices[selectedAnswer]}
          {selectedAnswer === question.correctAnswer ? <span className="ml-2 text-green-700">
                ✓ Correct
              </span> : <span className="ml-2 text-red-700">
                ✗ Incorrect
              </span>}
        </div>}
    </div>;
}

export default Question;