import React from 'react';
// Import the AddEmployeeForm component 
// import AddEmployeeForm from '../../components/Admin/AddEmployeeForm/AddEmployeeForm';
// Import the AdminMenu component 
import AdminMenu from '../../components/Admin/MangerMenu/MangerMenu';
import AddQuestionForm from '../../components/Admin/AddQuestionForm';
import FileUpload from '../../components/Admin/QustionInserting/FileUpload';

function AddQuestionPast(props) {
  return <div>
      <div class="container mx-auto admin-pages">
        <div class="flex flex-wrap">
          <div class="w-full md:w-3/12 admin-left-side">
            <AdminMenu />
          </div>
          <div class="w-full md:w-9/12 admin-right-side">
            <FileUpload />
          </div>
        </div>
      </div>
    </div>;
}

export default AddQuestionPast;