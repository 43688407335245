import React, { useState, useEffect, useCallback, useMemo } from "react";
import Lottie from "lottie-react";
import loadingAnimation from "../Lottia/loading.json";
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaFlag } from "react-icons/fa";
import questionService from "../../../services/question.service";
import { useAuth } from "../../../Contexts/AuthContext";
import Subject from './Subjects';
import Question from './Question';
import NoQuestionCard from './NoQuestionCard '; // Ensure this component is imported
import login from '.././../../assets/images/banner.png';

function OnlineTest() {
  const [questions, setQuestions] = useState([]);
  const { student } = useAuth();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [grade, setGrade] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flags, setFlags] = useState({});
  const [time, setTime] = useState(10800); // Start from 10800 seconds (3 hours)
  const [showTest, setShowTest] = useState(false);
  const [score, setScore] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [isTimeUp, setIsTimeUp] = useState(false); // Flag to track if time is up
  const [isProgressVisible, setIsProgressVisible] = useState(false);

  const fetchQuestions = useCallback(
    async () => {
      if (!grade) return;
      setIsLoading(true);
      try {
        const response = await questionService.getAllQuestions(grade);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "success" && Array.isArray(data.data)) {
          setQuestions(data.data);
        } else {
          setQuestions([]); // Ensure empty array for no questions
        }
      } catch (err) {
        console.error("Error fetching questions:", err);
        setError("Failed to load questions. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    },
    [grade]
  );

  useEffect(
    () => {
      fetchQuestions();
    },
    [fetchQuestions]
  );

  useEffect(
    () => {
      if (showTest) {
        const timer = setInterval(() => {
          setTime(prevTime => {
            if (prevTime > 0) {
              return prevTime - 1;
            } else {
              clearInterval(timer);
              setIsTimeUp(true);
              handleSubmit();
              return 0;
            }
          });
        }, 1000);
        return () => clearInterval(timer);
      }
    },
    [showTest]
  );

  const handleAnswerChange = (questionId, answer) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [questionId]: answer }));
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prevIndex => prevIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    }
  };

  const handleSubmit = async () => {
    let correctAnswersCount = 0;
    questions.forEach(question => {
      const selectedAnswer = answers[question.id];
      const correctAnswer = question.correctAnswer;
      if (selectedAnswer === correctAnswer) {
        correctAnswersCount++;
      }
    });
    setScore(correctAnswersCount);
    setShowResults(true);
    const student_id = student.student_id;
    const result = { grade, score: correctAnswersCount, student_id };
    try {
      const response = await questionService.postResult(result);
      if (response.ok) {
        console.log("Result submitted successfully");
      } else {
        console.error("Failed to submit result");
      }
    } catch (error) {
      console.error("Error submitting result:", error);
    }
  };

  const toggleFlag = questionIndex => {
    setFlags(prevFlags => ({
      ...prevFlags,
      [questionIndex]: !prevFlags[questionIndex]
    }));
  };

  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor(seconds % 3600 / 60);
    const secs = seconds % 60;
    return `${hours}:${mins < 10 ? "0" : ""}${mins}:${secs < 10
      ? "0"
      : ""}${secs}`;
  };

  const toggleProgressVisibility = () => {
    setIsProgressVisible(!isProgressVisible);
  };

  const currentQuestion = useMemo(() => questions[currentQuestionIndex], [
    currentQuestionIndex,
    questions
  ]);

  const startTest = selectedGrade => {
    setGrade(selectedGrade);
    setShowTest(true);
  };

  if (!showTest) {
    return <Subject startTest={startTest} />;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Lottie
          animationData={loadingAnimation}
          style={{ width: 150, height: 150 }}
        />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center">
        {error}
      </div>
    );
  }

  if (questions.length === 0) {
    return <NoQuestionCard />;
  }

  const numColumns = 8;
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div
      className="flex flex-col lg:flex-row"
      style={{ backgroundImage: `url(${login})` }}
    >
      <div className="fixed top-20 left-0 right-0 z-10 p-4 border-b ">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold">
            Time Taken: {formatTime(time)}
          </div>
          <button
            onClick={toggleProgressVisibility}
            className="lg:hidden z-10 flex items-center p-2"
          >
            {isProgressVisible
              ? <FaAngleDoubleRight size={24} />
              : <FaAngleDoubleLeft size={24} />}
          </button>
        </div>
      </div>

      <div
        className={`fixed top-0 lg:mt-16 mt-32 z-10 right-0 h-full w-3/5 transform transition-transform ${isProgressVisible
          ? "translate-x-0"
          : "translate-x-full"} lg:static lg:transform-none lg:w-1/5 p-4 border-r overflow-y-auto`}
      >
        <h2 className="text-xl font-bold mb-4 text-center">
          Question Progress
        </h2>
        <table className="table-auto w-full text-left border-collapse">
          <tbody>
            {Array.from(
              { length: Math.ceil(questions.length / numColumns) },
              (_, rowIndex) =>
                <tr
                  key={rowIndex}
                  className="w-10 h-10 px-2 py-1 text-xs border border-gray-300 text-center cursor-pointer"
                >
                  {Array.from({ length: numColumns }, (_, colIndex) => {
                    const questionIndex = rowIndex * numColumns + colIndex;
                    return questionIndex < questions.length ? <td key={colIndex} className={`bg-gray-300`} onClick={() => setCurrentQuestionIndex(questionIndex)}>
                          <div className="flex items-center justify-center">
                            <span className={`bg-gray-100 p-2 rounded ${answers[questions[questionIndex].id] ? (answers[questions[questionIndex].id] === questions[questionIndex].correctAnswer ? "bg-red-200" : "bg-red-200") : currentQuestionIndex === questionIndex ? "bg-gray-200" : "bg-white"}`}>
                              {questionIndex + 1} {flags[questionIndex] && <FaFlag className="ml-1 text-red-500" />}
                            </span>
                          </div>
                        </td> : <td key={colIndex} className="w-10 h-10 px-2 py-1 text-xs border border-gray-300" />;
                  })}
                </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="flex flex-col items-center justify-center lg:w-4/5 w-full pt-24 h-screen p-4">
        {showResults
          ? <div className="w-full">
              <h1 className="text-2xl font-bold text-center mb-4">
                Results
              </h1>
              <div className="text-center mb-4">
                Your Score: {score}/{questions.length}
              </div>
              {/* {questions.map((question, index) =>
                <div
                  key={question.id}
                  className={`p-4 mb-4 border rounded-lg ${answers[
                    question.id
                  ] === question.correctAnswer
                    ? "bg-green-200"
                    : "bg-red-200"}`}
                >
                  <h2 className="text-lg font-semibold mb-2">
                    {index + 1}: {question.question}
                  </h2>
                  <ul className="list-none">
                    {Object.keys(question)
                      .filter(key => key.startsWith("choice"))
                      .map(key =>
                        <li key={`${question.id}-${key}`} className="mb-2">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name={question.id}
                              checked={answers[question.id] === key}
                              readOnly
                              className="mr-2"
                            />
                            <span
                              className={`flex-1 p-2 rounded ${answers[
                                question.id
                              ] === key
                                ? key === question.correctAnswer
                                  ? "bg-green-200"
                                  : "bg-red-200"
                                : key === question.correctAnswer
                                  ? "bg-green-200"
                                  : ""}`}
                            >
                              {question[key]}
                            </span>
                            {answers[question.id] === key &&
                              <span className="ml-2">
                                {key === question.correctAnswer ? "✓" : "✗"}
                              </span>}
                          </label>
                        </li>
                      )}
                  </ul>
                </div>
              )} */}
            </div>
          : <div className="w-full relative ">
              <Question
                question={currentQuestion}
                index={currentQuestionIndex}
                onAnswerChange={handleAnswerChange}
                selectedAnswer={answers[currentQuestion.id]}
                onFlagToggle={toggleFlag}
                isFlagged={flags[currentQuestionIndex]}
                showResults={showResults}
              />
              <div className="absolute bottom-4 left-4 mt-4">
                <button
                  onClick={handlePrevious}
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                  disabled={currentQuestionIndex === 0}
                >
                  Previous
                </button>
              </div>
              <div className="absolute bottom-4 right-4 mt-4">
                {isLastQuestion
                  ? <button
                      onClick={handleSubmit}
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Submit
                    </button>
                  : <button
                      onClick={handleNext}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      disabled={currentQuestionIndex === questions.length - 1}
                    >
                      Next
                    </button>}
              </div>
            </div>}
      </div>
    </div>
  );
}

export default OnlineTest;
